<template>
  <b-card title="Slider">
    <b-tabs>
      <b-tab
        active
        @click="$router.push('/add-slider')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Slider</span>
        </template>

        <AddSlider />
      </b-tab>
      <b-tab @click="$router.push('/all-sliders')">
        <template #title>
          <feather-icon icon="HomeIcon" data-testid="slider-list"/>
          <span> All Sliders</span>
        </template>
        <router-view />
        <!-- <ViewSliders></ViewSliders> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import AddSlider from './AddSlider.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    AddSlider,
  },
  data() {
    return {

    }
  },
}
</script>
